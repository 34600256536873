import { Box, CheckBox } from "grommet";
import { Previous } from "grommet-icons";
import React from "react";
import { EventEmitter } from "../../components/elements/event_emitter";
import { FormFieldInput } from "../../components/elements/form_field";
import PrimaryButton from "../../components/elements/primary_button";
import StandardText from "../../components/elements/standard_text";
import NavigationFrame from "../../components/nav_frame";
import settingService from "../../service/setting_service";
import { getSimpleDateTime } from "../../service/utils";
import SalesforceOrders from "../../components/salesforce/orders";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configuration: { version: "", force_update: false },
    };
  }
  componentDidMount = () => {
    settingService.getConfiguration().then((res) => {
      console.log(res.data.data[0]);
      if (res.data.data.length) {
        this.setState({ configuration: res.data.data[0] });
      }
    });
  };

  onConfigurationChange = (field, value) => {
    const config = this.state.configuration;
    config[field] = value;
    this.setState({ ...config });
  };

  saveConfig = () => {
    settingService.saveConfiguration(this.state.configuration).then((_) => {
      EventEmitter.dispatch("showMessage", {
        message: "Save config successfully",
      });
    });
  };

  render() {
    return (
      <NavigationFrame>
        <Box
          width={"xlarge"}
          pad="medium"
          style={{ height: "100vh", minHeight: "auto", overflowY: "auto" }}
          gap="medium"
        >
          <Box
            justify="between"
            width={"full"}
            pad={{ vertical: "small" }}
            direction="row"
            align="center"
            style={{ minHeight: "auto" }}
          >
            <PrimaryButton
              label={"Back"}
              onClick={() => {
                if (typeof window === "undefined") return;
                window.history.back();
              }}
              icon={<Previous size="small" color={"#fff"} />}
            />
            <PrimaryButton label={"Save"} onClick={() => this.saveConfig()} />
          </Box>
          <Box width={"full"} gap="medium" style={{ minHeight: "auto" }}>
            <Box width={"full"} gap="small" pad={{ horizontal: "small" }}>
              <StandardText label={"MEOR APP Version"} bold size={"medium"} />
              <Box direction="row" gap="small" style={{ minHeight: "auto" }}>
                <FormFieldInput
                  label={"Current version"}
                  size="small"
                  value={this.state.configuration.version}
                  onChange={(event) =>
                    this.onConfigurationChange("version", event.target.value)
                  }
                />
                <FormFieldInput
                  label={"Built date"}
                  size="small"
                  value={
                    this.state.configuration.built_date
                      ? getSimpleDateTime(this.state.configuration.built_date)
                      : ""
                  }
                  disabled
                />
              </Box>
              <Box
                width={"full"}
                align="center"
                pad={"xsmall"}
                direction="row"
                gap="small"
              >
                <CheckBox
                  checked={this.state.configuration.force_update}
                  size={"small"}
                  onChange={(event) =>
                    this.onConfigurationChange(
                      "force_update",
                      event.target.checked
                    )
                  }
                />
                <StandardText label={"Force update"} size="small" />
              </Box>
            </Box>
          </Box>
          <SalesforceOrders />
        </Box>
      </NavigationFrame>
    );
  }
}

export default Settings;
