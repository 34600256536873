import { Box, DateInput } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getSalesForceDifference, syncMissingOrdersSalesforce } from "../../service/order_service";
import FormField from "../elements/form_field";
import PrimaryButton from "../elements/primary_button";
import StandardText from "../elements/standard_text";

const initDate = () => {
  let end_date = moment().endOf("date"),
    start_date;
  const today = new Date().getDay();
  switch (today) {
    case 0:
      // today is sunday
      start_date = moment().subtract(6, "days").startOf("date");
      break;
    case 1:
      // today is monday
      start_date = moment().subtract(7, "days").startOf("date");
      end_date = moment().startOf("date");
      break;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      // today is tuesday
      start_date = moment()
        .subtract(today - 1, "days")
        .startOf("date");
      break;

    default:
      break;
  }
  return {
    start_date: start_date.format("YYYY-MM-DDTHH:mm:ss.000Z"),
    end_date: end_date.format("YYYY-MM-DDTHH:mm:ss.000Z"),
  };
};

const SalesforceOrders = () => {
  const [filters, setFilters] = useState(initDate());
  const [_platform, setPlatform] = useState(0);
  const [_saleforce, setSaleForce] = useState(0);

  useEffect(() => {
    reload(filters);
  }, [])

  const reload = (filters) => {
    getSalesForceDifference(filters)
    .then(res => {
        const {platform, salesforce} = res.data;
        setPlatform(platform);
        setSaleForce(salesforce);
    })
  }

  const syncSaleforce = () => {
    syncMissingOrdersSalesforce()
        .then(_ => reload());
  }

  return (
    <Box width={"full"} gap="medium" style={{ minHeight: "auto" }}>
      <Box width={"full"} gap="small" pad={{ horizontal: "small" }}>
        <StandardText label={"Salesforce commandes"} bold size={"medium"} />
        <Box direction="row" gap="small" style={{ minHeight: "auto" }}>
          <Box width={"medium"}>
            <FormField
              label={"From / To"}
              size="small"
              component={
                <DateInput
                  size="small"
                  value={[filters.start_date, filters.end_date]}
                  buttonProps={{
                    label: <StandardText bold label={`${moment(filters.start_date).format('DD/MM')} -> ${moment(filters.end_date).format("DD/MM")}`} />,
                  }}
                  onChange={(event) => {
                    const nextValue = event.value;
                    const _filters = {
                      ...filters,
                      start_date: nextValue[0],
                      end_date: nextValue[1],
                    };
                    reload(_filters);
                    setFilters(_filters);
                  }}
                />
              }
            />
          </Box>
        </Box>
        <Box gap="small" direction="row">
            <StandardText label={"Total orders on Salesforce: "} />
            <StandardText label={_saleforce} bold/>
        </Box>
        <Box gap="small" direction="row">
            <StandardText label={"Total orders on platform: "} />
            <StandardText label={_platform}  bold/>
        </Box>
        <Box width={'small'} pad={{top: 'small'}}>
            <PrimaryButton label={"Sync missing orders"} 
                onClick={syncSaleforce}
            />
        </Box>
      </Box>
    </Box>
  );
};

export default SalesforceOrders;
