const axios = require('axios');
const { baseAPI } = require('../config/config.json');
const { getToken } = require('./storage_service');

const buildHeader = () => {
    return ({
        'Authorization': `Bearer ` + getToken(),
    })
}
module.exports = {
    getConfiguration: () => {
        return axios.get(`${baseAPI}/api/configurations`, { headers: buildHeader() })
    },
    saveConfiguration: (config) => {
        return axios.put(`${baseAPI}/api/configurations/${config.id}`, {data: config}, { headers: buildHeader() })
    }
}